<template>
  <div class='back_cont'>
    <div class="icon_box" @click='back'>
      <van-icon name="arrow-left" :color='"#313131"' class='backicon' :size='"24px"'/>
    </div>
    <div class="tits">课程列表</div>
    <div class="kf"> </div>
  </div>
  <div class='header_cont'></div>

  <div class="m-box">
      <div class="list" @click="toDetail(item)" v-for="(item,index) in listData" :key="item.NAME">
          <div class="imgs"><img :src="item.HEAD_PIC_URL" alt=""></div>
          <div class="cont">
             <span></span>
             <span class="buy-k">立即报名咨询</span>
          </div>
      </div>
  </div>
</template>

<script>
import {useRouter, useRoute} from 'vue-router'
import {onMounted, ref, computed, reactive, toRefs} from "vue";
import {useStore} from 'vuex'
import homeApi from '@axios/home'
export default {
  name: "courseList",
  setup(){
    const router = useRouter();
    const toDetail =(item)=> {
      router.push({
        name: 'courseDetail',
        query: {
          productId : item.ID
        }
      })
    }

    const listData = ref([]);
    const getList =()=> {
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        function: "activityProductForNurse",
        source:'h5'
      }))
      formData.append('encryption', false)
      homeApi.getList(formData).then((res)=> {
        listData.value = res.data.sort(function(a,b){
          return a.SORT - b.SORT;
        });

      })
    }
    getList();
    const back = ()=> {
      console.log('111')
      window.location.href ='http://m.yihu365.cn/public.shtml';
    }
    return {
      toDetail,
      listData,
      back
    }
  }
}
</script>

<style scoped lang="scss">
  .m-box{
    box-sizing: border-box;
    padding:30px 20px;
    .list{
      background-color: #faf9f9;
      box-sizing: border-box;
      border-radius: 16px;
      width: 100%;
      padding-bottom: 20px;
      margin-bottom: 20px;
      img{
        width: 100%;
        border-radius: 16px 16px 0 0;
      }
    }
    .cont{
      display: flex;
      justify-content: space-between;
      font-size: 28px;
      margin-top: 10px;
      padding:0 20px;
    }
    .buy-k{
      display: block;
      width: 250px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      color:#FFFFFF;
      font-size: 30px;
      background-image: linear-gradient(to right, #00c291 , #04E1A9);
      border-radius: 40px;
    }
  }

  .back_cont{
    width: 100%;
    position: fixed;
    display: flex;
    //justify-content: center;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    line-height: 88px;
    border-bottom: 1px solid #F5F5F5;
    box-sizing: border-box;
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #313131;
    background: #fff;
    z-index: 9999;
    .tits{
      text-align: center;
    }
    div{
      box-sizing: border-box;
      width: 33.3%;
    }
    .icon_box{
      margin-left: 10px;
      box-sizing: border-box;
      padding-top: 12px;
    }
    .backicon{
      //position: absolute;
      //left: 15px;
      //width: 20px;

    }
  }
</style>
